<template>
  <div class="login-container">
    <header class="top-div">
      <div class="logo" />
    </header>
    <div class="center-div">
      <div class="login_content">
        <div class="login_bg"></div>
        <div class="login_main">
          <!-- <div class="logo_txt">智慧工地管理平台</div> -->
          <div class="login_card_container">
            <div class="login_body">
              <div class="login_header" style="font-size:24px; margin-bottom: 33px">
                用户登录
              </div>
              <el-form ref="loginForm"
                :model="loginForm"
                :rules="rules"
                status-icon
                label-position="top"
                label-width="113px">
                <el-form-item label="用户名" prop="loginMobile">
                  <el-input type="text"
                            placeholder=""
                            v-model="loginForm.loginMobile"
                            autocomplete="off" />
                </el-form-item>
                <el-form-item label="密码" prop="loginPwd">
                  <el-input type="password"
                            placeholder=""
                            v-model="loginForm.loginPwd"
                            autocomplete="off" />
                </el-form-item>
                <el-form-item prop="rememberMe" style="text-align:right">
                  <el-checkbox v-model="loginForm.rememberMe"
                              :true-label="1"
                              :false-label="0">
                    记住密码
                  </el-checkbox>
                </el-form-item>
                <el-button class="login-btn"
                    @click="handleLogin"
                    type="primary"
                    size="medium">
                  登录
                </el-button>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer class="footer-div">关于智慧工地平台V1.0.0 | 版权所有 © 上海建工羿云科技有限公司</footer>
  </div>
</template>

<script>
import Cookies from 'js-cookie'
export default {
  name: 'Login',
  components: {},
  data(){
    return {
      loginForm: {
        loginMobile: '',
        loginPwd: '',
        rememberMe: 0
      },
      rules: {
        loginMobile: [
          { required: true, message:'请输入用户名', trigger: 'blur'}
        ],
        loginPwd: [
          { required: true, message:'请输入密码', trigger: 'blur'}
        ]
      }
    }
  },
  methods:{
    handleLogin(){
      this.$refs['loginForm'].validate((valid) => {
        if(valid){
          this.$api.user.login(this.loginForm).then( res => {
            if(res.code===0){
              console.log(res.data);
              Cookies.set('loginMobile', this.loginForm.loginMobile);
              Cookies.set('loginPwd', this.loginForm.loginPwd);
              // Cookies.set('loginSys', 'enterprise');
              // localStorage.setItem('loginMobile', this.loginForm.loginMobile);
              localStorage.setItem('tokenId', res.data.token);
              localStorage.setItem('userId', res.data.userId);
              localStorage.setItem('memberName', res.data.name);
              localStorage.setItem('companyId', res.data.companyId);
              localStorage.setItem('companyName', res.data.companyName);
              localStorage.setItem('isOperation', res.data.isOperation);
              localStorage.setItem('type', res.data.type);
              localStorage.setItem('isManager', res.data.isOperation==='1' ? true : (res.data.type!=='3' ? true : false));
              this.$router.push('/home');
            }
          })
        }else{
          this.$message.warning('校验失败')
        }
      })
    },
    initData(){
      this.loginForm.loginMobile = Cookies.get('loginMobile') || ''
      this.loginForm.loginPwd = Cookies.get('loginPwd') || ''
    }
  },
  mounted(){
    this.initData
  }
}
</script>
<style lang="stylus" scoped>
@import '../../styles/base.styl'
.login-container
  height 100vh
  position relative
  .top-div
    background #fff
    .logo
      width 1440px
      height 100px
      margin 0 auto
      background url('../../assets/logo_dk.png') no-repeat left center
  .center-div
    height calc(100vh - 175px)
    min-height 654px
    background $new-theme-color
    .login_content
      width 1412px
      height 100%
      margin 0 auto
      display flex
      align-items center
      justify-content center
      .login_bg
        flex 1
        height 536px
        background-color #f00
        background url('../../assets/login/login_img.png') no-repeat center
      .login_main
        display flex
        flex-direction column
        height 100%
        width 556px
        justify-content center
        .logo_txt
          width 425px
          text-align center
          color #fff
          font-size 30px
          margin-bottom 42px
        .login_card_container
          width 474px
          .login_body
            background-color #ffffff
            padding 62px 59px
            height auto
            box-sizing border-box

            >>> .el-form-item
              margin-bottom 24px !important

            >>> .el-input input
              background transparent !important
              -webkit-appearance none !important
              color #333 !important
              font-size $title-size
              height 44px !important
              line-height 44px !important
              caret-color #409eff !important
              border-color #d3d3d3 !important

            >>> .el-form--label-top
              >>> .el-form-item__label
                font-size $small-txt-size !important

            >>> .el-form--label-top
              >>> .el-form-item__label
                padding 0 !important
    .sublogo
      font-size 32px
      color #ffffff
      text-align center
      vertical-align bottom
      font-weight bold
      margin-bottom 76px
      margin-top 106px
    .login-box
        text-align center
        background #fcfcfc
        padding 76px 50px
        box-shadow 0 3px 3px 0 rgb(0 0 0 / 35%)
        border-radius 3px
      .login-btn
        font-size 18px
        letter-spacing 2px
        width 100%
        height 50px
        background #377CED
        border 0
  .footer-div
    width 100%
    height 75px
    padding-top 39px
    box-sizing border-box
    color #999
    font-size 14px
    text-align center
    position absolute
    background #fff
</style>
<style lang="stylus">
.login-container
  .el-form-item__label
    font-size 14px
    color #8C8C8C
  // 隐藏必填项的*
  .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before
    content ''
    color #F56C6C
    margin-right 4px
</style>